import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, useFormikContext } from "formik";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { CategoriesTableEditableRow } from "./CategoriesTableEditableRow";
import { CategoriesTableReadonlyRow } from "./CategoriesTableReadonlyRow";
import { useTheme } from "../../contexts/ThemeContext";
import { useCategories } from "./contexts/CategoriesContext";
import { useCategoriesActions } from "./hooks/useCategoriesActions";
import { categoryValidationSchema } from "./schemas/CategoryValidationSchema";
import { CategoryDetails } from "./models/CategoryDetails";
import { Loader } from "../common/progress/Loader";
import { usePreserve } from "../../contexts/PreserveContext";
import { useEffect } from "react";
import { useLocalization } from "../../contexts/LocalizationContext";
import { TableFooter } from "../common/TableFooter";

const defaultCapacity = 10;

export const CategoriesTable = () => {
  const formik = useFormikContext<CategoryDetails>();
  const { theme, buttonStyle } = useTheme();
  const { values, initialValues, setTouched } = formik;
  const { isLoading, handleDelete, handleCreate, handleUpdate } = useCategoriesActions();
  const { editModeId, categories, capacity } = useCategories();
  const { onCapacityChange, onEditModeIdChange } = useCategories();
  const { safeExecute, setCurrentState, setInitialState } = usePreserve();
  const { translate } = useLocalization();

  useEffect(() => {
    setCurrentState(values);
  }, [values]);

  useEffect(() => {
    setInitialState(initialValues);
  }, [initialValues]);

  const handleAdd = () => safeExecute(() => onEditModeIdChange(0));

  const handleClick = async () => {
    try {
      await categoryValidationSchema(translate).validate(values, { abortEarly: false });
      setTouched({});
      if (editModeId === 0) await handleCreate(values);
      else await handleUpdate(editModeId, values);
    } catch (errors) {
      setTouched({ name: true });
    }
  };

  if (isLoading) return <Loader />;

  const newCategories = categories.slice(0, capacity);

  return (
    <Form>
      <Table striped bordered>
        <thead>
          <tr className="d-none d-xl-table-row">
            <th>{translate("plugins.id")}</th>
            <th>{translate("plugins.name")}</th>
            <th colSpan={2} className="text-center">
              <OverlayTrigger overlay={<Tooltip>{translate("common.add")}</Tooltip>}>
                <Button className="p-0" variant="transparent" aria-label={translate("common.add")} onClick={handleAdd}>
                  <FontAwesomeIcon icon={faPlusCircle} style={{ color: theme.backgroundColor }} />
                </Button>
              </OverlayTrigger>
            </th>
          </tr>
        </thead>
        <tbody>
          {newCategories.map((x, i) =>
            x.id === editModeId ? (
              <CategoriesTableEditableRow key={x.id} index={i + 1} handleSave={handleClick} />
            ) : (
              <CategoriesTableReadonlyRow key={x.id} item={x} index={i + 1} onDelete={() => handleDelete(x.id)} />
            )
          )}
          {editModeId === 0 && <CategoriesTableEditableRow index={categories.length + 1} handleSave={handleClick} />}
          <TableFooter
            expand={() => onCapacityChange(defaultCapacity)}
            expandAll={() => onCapacityChange(categories.length)}
            capacity={defaultCapacity}
            showMenu={!!newCategories.length && categories.length > defaultCapacity}
            showEmpty={!newCategories.length && editModeId === -1}
            colSpan={5}
          />
        </tbody>
      </Table>
      <Button style={buttonStyle} onClick={handleAdd}>
        {translate("common.addNew")}
      </Button>
    </Form>
  );
};
