import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { PushNotification } from "../models/PushNotification";
import { apiRoutes } from "../../../apiRoutes";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../../identity/models/ExtendedUser";

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<PushNotification[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { request } = useFetch();
  const { user } = useAuth0<ExtendedUser>();
  const userId = user?.user_metadata?.id;
  const accountId = user?.user_metadata?.selectedAccount.id;

  const getNotifications = useCallback(async () => {
    if (accountId && userId) {
      setIsLoading(true);
      await request({
        route: apiRoutes.notifications.getUserNotifications(accountId, userId),
        onSuccess: async (response) => setNotifications(await response.json()),
        onAny: () => setIsLoading(false)
      });
    }
  }, [request, accountId, userId]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return { notifications, isLoading };
};
