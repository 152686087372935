import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { usePluginActions } from "./hooks/usePluginActions";
import { pluginValidationSchema } from "./schemas/PluginValidationSchema";
import { useTheme } from "../../contexts/ThemeContext";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useState } from "react";
import { RejectionReasonModal } from "./RejectionReasonModal";
import { PluginDetails } from "./models/PluginDetails";
import { useParams } from "react-router-dom";
import { ReviewType } from "./models/ReviewType";
import DOMPurify from "dompurify";

type PendingPluginButtonGroupProps = { canBeApproved: boolean };

export const PendingPluginButtonGroup = ({ canBeApproved }: PendingPluginButtonGroupProps) => {
  const formik = useFormikContext<PluginDetails>();
  const { buttonStyle } = useTheme();
  const { user } = useAuth0<ExtendedUser>();
  const { isLoading, pluginActions } = usePluginActions();
  const { values, setTouched, handleSubmit: submit } = formik;
  const [showModal, setShowModal] = useState(false);
  const { translate } = useLocalization();
  const params = useParams();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const pluginId = params.pluginId as string;

  const handleSave = async () => {
    try {
      values.htmlDescription = DOMPurify.sanitize(values.htmlDescription);
      values.htmlDocumentation = DOMPurify.sanitize(values.htmlDocumentation);
      await pluginValidationSchema(translate).validate(values, { abortEarly: false });
      setTouched({});
      if (isSystemAdmin) await pluginActions.handleUpdate(parseInt(pluginId), values);
      else await pluginActions.handleUpdatePending(parseInt(pluginId), values);
    } catch (errors) {
      submit();
    }
  };

  if (isLoading) return <Loader />;

  if (isSystemAdmin) {
    return (
      <>
        {canBeApproved ? (
          <Button
            style={buttonStyle}
            onClick={() => pluginActions.handleRequestReview(parseInt(pluginId), ReviewType.Approved)}
          >
            {translate("plugins.approve")}
          </Button>
        ) : (
          <OverlayTrigger overlay={<Tooltip>{translate("versions.reviewPluginVersion")}</Tooltip>}>
            <span className="d-inline-block">
              <Button style={buttonStyle} disabled>
                {translate("plugins.approve")}
              </Button>
            </span>
          </OverlayTrigger>
        )}
        <Button variant="danger" className="mx-2" onClick={() => setShowModal(true)}>
          {translate("plugins.reject")}
        </Button>
        {showModal && (
          <RejectionReasonModal
            onContinue={(rejectionReason) =>
              pluginActions.handleRequestReview(parseInt(pluginId), ReviewType.Rejected, rejectionReason)
            }
            onClose={() => setShowModal(false)}
          />
        )}
      </>
    );
  }

  return (
    <Button style={buttonStyle} className="me-2" type="button" onClick={() => handleSave()}>
      {values.publishToRWSAppStore ? translate("plugins.submit") : translate("common.save")}
    </Button>
  );
};
