import { useEffect, useState } from "react";
import { usePreserve } from "../../../contexts/PreserveContext";
import { useFormikContext } from "formik";
import { DisabledFields } from "../models/DisabledFields";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { usePlugin } from "../contexts/PluginContext";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { useDownload } from "../../../hooks/useDownload";
import { apiRoutes } from "../../../apiRoutes";
import { ResourceType } from "../models/ResourceType";
import { PluginDetails } from "../models/PluginDetails";
import { Resource } from "../models/Resource";
import { useFetch } from "../../../hooks/useFetch";
import { getExistingResourcesSize, validateResourcesTypeAndSize } from "../../../utils";

export const usePluginDetailsPartial = (hasLockedFields: boolean) => {
  const { setCurrentState, setInitialState } = usePreserve();
  const { values, initialValues, setFieldValue } = useFormikContext<PluginDetails>();
  const [disabledFields, setDisabledFields] = useState<DisabledFields>({});
  const { translate } = useLocalization();
  const { showModal } = useFeedback();
  const { handleDownload } = useDownload();
  const { pluginDetails: plugin } = usePlugin();
  const { pushNotification } = useFeedback();
  const { request } = useFetch();

  useEffect(() => {
    setCurrentState(values);
  }, [values]);

  useEffect(() => {
    setInitialState(initialValues);
  }, [initialValues]);

  useEffect(() => {
    const fields = new DisabledFields(hasLockedFields);

    fields.sharedParentProductIds = hasLockedFields || values.parentProductDetailsId === 0;

    setDisabledFields(fields);
  }, [hasLockedFields, values.parentProductDetailsId]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) setFieldValue("iconUpload", e.target.files[0]);
  };

  const handleFieldDisable = (field: keyof DisabledFields) => {
    setDisabledFields((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleStatusChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    const handleContinue = () => {
      setFieldValue("publishToRWSAppStore", !values.publishToRWSAppStore);
      showModal(null);
    };

    const { unpublishedPluginDetails, publishToRWSAppStore } = plugin;

    if (!unpublishedPluginDetails && publishToRWSAppStore && !checked) {
      showModal({
        isVisible: true,
        message: translate("versions.versionsPrivate"),
        handleClose: () => showModal(null),
        handleContinue
      });
    } else setFieldValue("publishToRWSAppStore", !values.publishToRWSAppStore);
  };

  const handleResourceDownload = async (resourceType: ResourceType, pluginId: number) => {
    await handleDownload(
      apiRoutes.pluginDetails.downloadPluginResource(pluginId),
      JSON.stringify({ resourceType }),
      "POST",
      "application/json"
    );
  };

  const savePluginResource = async (files: File[], resourceType: ResourceType) => {
    const existingResourcesSize =
      resourceType === ResourceType.Description
        ? getExistingResourcesSize(values.descriptionResources)
        : getExistingResourcesSize(values.documentationResources);

    const { isValid, message } = validateResourcesTypeAndSize(files, existingResourcesSize);

    if (!isValid) {
      pushNotification({ message: translate(message), type: "danger" });
      return;
    }

    await handleSavePluginDetailsResource(files, resourceType);
  };

  const deletePluginResource = async (resourceId: number, resourceType: ResourceType) => {
    await handleDeletePluginResource(resourceId, resourceType);
  };

  const handleSavePluginDetailsResource = async (files: File[], resourceType: ResourceType) => {
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));
    formData.set("type", `${resourceType}`);

    const onSuccess = async (response: Response) => {
      const json = await response.json();
      if (Array.isArray(json)) {
        updateResourceInFormikContext(json, resourceType, "add");
      }

      pushNotification({
        message: translate("plugins.imagesUploadedSucessfully"),
        type: "success"
      });
    };

    await request({
      route: apiRoutes.resources.addPluginDetailsResources(values.id),
      method: "POST",
      body: formData,
      onSuccess,
      onFailure: async (response: Response) => {
        const json = await response.json();
        pushNotification({ ...json, type: "danger" });
      }
    });
  };

  const handleDeletePluginResource = async (resourceId: number, resourceType: ResourceType) => {
    await request({
      route: apiRoutes.resources.deletePluginDetailsResource(values.id, resourceId),
      method: "DELETE",
      onSuccess: async () => {
        updateResourceInFormikContext([{ id: resourceId }] as Resource[], resourceType, "remove");

        pushNotification({
          message: translate("plugins.imageDeletedSucessfully"),
          type: "success"
        });
      },
      onFailure: async (response: Response) => {
        const json = await response.json();
        pushNotification({ ...json, type: "danger" });
      }
    });
  };

  const updateResourceInFormikContext = (
    resources: Resource[],
    resourceType: ResourceType,
    action: "add" | "remove"
  ) => {
    const getUpdatedResources = (existingResources: Resource[]) => {
      return action === "add"
        ? [...existingResources, ...resources]
        : existingResources.filter((x) => !resources.some((resource) => resource.id === x.id));
    };

    switch (resourceType) {
      case ResourceType.Description:
        setFieldValue("descriptionResources", getUpdatedResources(values.descriptionResources));
        break;
      case ResourceType.Documentation:
        setFieldValue("documentationResources", getUpdatedResources(values.documentationResources));
        break;
    }
    setFieldValue("resourcesModified", true);
  };

  return {
    disabledFields,
    handleFileChange,
    handleFieldDisable,
    handleStatusChange,
    handleResourceDownload,
    savePluginResource,
    deletePluginResource
  };
};
