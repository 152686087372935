import { Col, Container, Row, Image, Spinner } from "react-bootstrap";
import { PushNotification } from "./models/PushNotification";
import { useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { NotificationStatus } from "./models/NotificationStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notificationStatusClassMap, notificationStatusIconMap } from "./models/NotificationStatusHelper";
import { useLocalization } from "../../contexts/LocalizationContext";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { apiRoutes } from "../../apiRoutes";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";

type NotificationProps = { notification: PushNotification };

export const Notification = ({ notification }: NotificationProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { request } = useFetch();
  const date = new Date(notification.createdAt as Date);
  const { translate } = useLocalization();
  const { user } = useAuth0<ExtendedUser>();
  const accountId = user?.user_metadata?.selectedAccount.id;

  const handleView = () => {
    if (!accountId) return;
    setIsLoading(true);
    request({
      route: apiRoutes.notifications.updateNotification(accountId, notification.id),
      body: JSON.stringify({ notificationStatus: NotificationStatus.Complete }),
      contentType: "application/json",
      method: "PUT",
      onSuccess: async () => {
        window.location.href = notification.callToActionUrl;
      }
    });
  };

  return (
    <Container key={notification.id}>
      <Row className="my-2">
        <Col xs={3} className="d-flex flex-column align-items-center my-2" title={notification.title}>
          <Image width={75} src={notification.imageSource} alt={notification.title} />
          <p className="m-0 mt-2 fw-bold text-truncate" style={{ width: 90 }}>
            {notification.title}
          </p>
        </Col>
        <Col xs={9} className="border-start d-flex flex-column justify-content-between my-2">
          <div>{notification.message}</div>
          {notification.rejectionReason && (
            <div>
              <p className="m-0 text-wrap">
                <strong>{translate("notification.reason")}</strong>
                {notification.rejectionReason}
              </p>
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center">
            {isLoading ? (
              <Spinner size="sm" />
            ) : (
              <p className="m-0 text-decoration-underline text-primary cursor-pointer" onClick={handleView}>
                {translate("notification.view")}
              </p>
            )}
            <div>
              <p className="m-0" style={{ fontSize: 12 }}>
                {`${date.toDateString()} - ${date.toLocaleTimeString()}`}
              </p>
              {notification.status !== NotificationStatus.Active && (
                <p className="m-0" style={{ fontSize: 12 }}>
                  {translate("notification.status")}
                  {NotificationStatus[notification.status]}
                  <FontAwesomeIcon
                    className={`${notificationStatusClassMap.get(notification.status)} ms-1`}
                    icon={notificationStatusIconMap.get(notification.status) as IconProp}
                  />
                </p>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
