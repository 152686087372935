import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { ContentType, RestMethod, useFetch } from "../../../hooks/useFetch";
import { usePlugin } from "../contexts/PluginContext";
import { DeleteAction } from "../models/DeleteAction";
import { toFormData } from "../../../utils";
import { Status } from "../models/Status";
import { Action } from "../models/Action";
import { usePlugins } from "../contexts/PluginsContext";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { PluginDetailsBase } from "../models/PluginDetailsBase";
import { apiRoutes } from "../../../apiRoutes";
import { routes } from "../../../main/routes";
import { ReviewType } from "../models/ReviewType";

export const usePluginActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification, showModal } = useFeedback();
  const { request } = useFetch();
  const { getPlugin } = usePlugin();
  const { getPlugins } = usePlugins();
  const navigate = useNavigate();
  const { translate } = useLocalization();
  const baseUrl = process.env.PUBLIC_URL;

  const onFailure = async (response: Response) => {
    const json = await response.json();
    pushNotification({ ...json, type: "danger" });
  };

  const onAny = () => setIsLoading(false);

  const changeStatus = async (status: Status, pluginId: number) => {
    await handleRequest(
      JSON.stringify({ futureStatus: status }),
      apiRoutes.pluginDetails.changePluginStatus(pluginId),
      "PUT",
      translate("plugins.pluginDetailsUpdated"),
      "application/json",
      status === Status.NotPublished ? translate("plugins.pluginVersionsDeactivated") : undefined
    );
  };

  const handleRequestReview = async (pluginId: number, reviewType: ReviewType, rejectionReason?: string) => {
    await handleRequest(
      JSON.stringify({ reviewType, rejectionReason }),
      apiRoutes.pluginDetails.reviewPluginRequest(pluginId),
      "PATCH",
      translate("plugins.pluginDetailsUpdated"),
      "application/json"
    );
  };

  const handleDelete = async (pluginId: number, deleteAction: DeleteAction) => {
    await handleDeleteRequest(
      pluginId,
      JSON.stringify({ deleteAction }),
      apiRoutes.pluginDetails.deletePlugin(pluginId),
      "DELETE",
      translate("plugins.removedPluginDetails")
    );
  };

  const reviewDeletionRequest = async (pluginId: number, reviewType: ReviewType) => {
    await handleDeleteRequest(
      pluginId,
      JSON.stringify({ reviewType }),
      apiRoutes.pluginDetails.reviewPluginDeletionRequest(pluginId),
      "PATCH",
      translate("plugins.sentDeletionReview")
    );
  };

  const requestDeletion = async (pluginId: number, deleteAction: DeleteAction) => {
    await handleDeleteRequest(
      pluginId,
      JSON.stringify({ deleteAction }),
      apiRoutes.pluginDetails.requestPluginDeletion(pluginId),
      "POST",
      translate("plugins.sentDeletionRequest")
    );
  };

  const handleCreate = async (value: PluginDetailsBase) => {
    await handleRequest(
      toFormData({ ...value, action: Action.Save }),
      apiRoutes.pluginDetails.createPlugin(),
      "POST",
      translate("plugins.pluginDetailsCreated")
    );
  };

  const handleCreateDraft = async (value: PluginDetailsBase) => {
    await handleRequest(
      toFormData({ ...value, action: Action.SaveAsDraft }),
      apiRoutes.pluginDetails.createPluginDraft(),
      "POST",
      translate("plugins.pluginDetailsCreated")
    );
  };

  const handleCreatePending = async (value: PluginDetailsBase) => {
    await handleRequest(
      toFormData({ ...value, action: Action.Submit }),
      apiRoutes.pluginDetails.createPluginInReview(),
      "POST",
      translate("plugins.pluginDetailsCreated")
    );
  };

  const handleUpdate = async (pluginId: number, value: PluginDetailsBase) => {
    await handleRequest(
      toFormData({ ...value, action: Action.Save }),
      apiRoutes.pluginDetails.updatePlugin(pluginId),
      "PUT",
      translate("plugins.pluginDetailsUpdated")
    );
  };

  const handleUpdateDraft = async (pluginId: number, value: PluginDetailsBase) => {
    await handleRequest(
      toFormData({ ...value, action: Action.SaveAsDraft }),
      apiRoutes.pluginDetails.updatePluginDraft(pluginId),
      "PUT",
      translate("plugins.pluginDetailsUpdated")
    );
  };

  const handleUpdatePending = async (pluginId: number, value: PluginDetailsBase) => {
    await handleRequest(
      toFormData({ ...value, action: Action.Submit }),
      apiRoutes.pluginDetails.updatePluginInReview(pluginId),
      "PUT",
      translate("plugins.pluginDetailsUpdated")
    );
  };

  const handleDeleteRequest = async (
    pluginId: number,
    body: string,
    route: string,
    method: RestMethod,
    successMessage: string
  ) => {
    const onSuccess = async (response: Response) => {
      if (pluginId && response.status === 201) {
        const json = await response.json();
        await getPlugin(json.id);
        navigate(routes.plugins.update(json.id, json.status));
      } else {
        navigate(`${baseUrl}/`);
        await getPlugins();
      }

      pushNotification({ message: successMessage, type: "success" });
    };

    const handleContinue = async () => {
      showModal(null);
      setIsLoading(true);
      await request({
        route,
        method,
        body,
        contentType: "application/json",
        onSuccess,
        onFailure,
        onAny
      });
    };

    showModal({
      isVisible: true,
      message: translate("plugins.removePlugin"),
      handleClose: () => showModal(null),
      handleContinue
    });
  };

  const handleRequest = async (
    body: FormData | string,
    route: string,
    method: RestMethod,
    successMessage: string,
    contentType?: ContentType,
    modalConfirmationMessage?: string
  ) => {
    const onSuccess = async (response: Response) => {
      const json = await response.json();
      const id = json.publishedPluginDetailsId ?? json.id;
      await getPlugin(id);
      navigate(routes.plugins.update(id, json.status));
      pushNotification({ message: successMessage, type: "success" });
    };

    setIsLoading(true);

    const createRequest = async () => {
      await request({
        route,
        method,
        body,
        contentType,
        onSuccess,
        onFailure,
        onAny
      });
    };

    if (modalConfirmationMessage) {
      showModal({
        isVisible: true,
        message: translate("plugins.removePlugin"),
        handleClose: () => showModal(null),
        handleContinue: createRequest
      });
    } else await createRequest();
  };

  return {
    isLoading,
    pluginActions: {
      handleCreate,
      handleCreateDraft,
      handleCreatePending,
      handleUpdate,
      handleUpdateDraft,
      handleUpdatePending,
      handleRequestReview,
      changeStatus,
      handleDelete,
      reviewDeletionRequest,
      requestDeletion
    }
  };
};
