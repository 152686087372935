import { Container, Row } from "react-bootstrap";
import { PushNotification } from "./models/PushNotification";
import { Notification } from "./Notification";
import { useLocalization } from "../../contexts/LocalizationContext";

type NotificationsListProps = { list: PushNotification[] };

export const NotificationsList = ({ list }: NotificationsListProps) => {
  const { translate } = useLocalization();

  return (
    <Container>
      <Row style={{ overflowY: "auto", maxHeight: 600 }}>
        <div className="p-0">
          {list.length > 0 ? (
            list.map((x, i) => (
              <>
                <Notification key={x.id} notification={x} />
                {i !== list.length - 1 && <hr className="m-0" aria-hidden />}
              </>
            ))
          ) : (
            <div className="p-2">{translate("notification.noNotifications")}</div>
          )}
        </div>
      </Row>
    </Container>
  );
};
