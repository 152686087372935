import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAnglesDown } from "@fortawesome/free-solid-svg-icons";

type TableFooterProps = {
  expand: () => void;
  expandAll: () => void;
  capacity: number;
  showMenu: boolean;
  showEmpty?: boolean;
  colSpan: number;
};

export const TableFooter = ({ capacity, showMenu, showEmpty, colSpan, expand, expandAll }: TableFooterProps) => {
  const { translate } = useLocalization();

  if (showEmpty) {
    return (
      <tr>
        <td colSpan={colSpan} className="text-center align-middle">
          {translate("account.noAccountsFound")}
        </td>
      </tr>
    );
  }

  if (showMenu) {
    return (
      <>
        <tr>
          <td colSpan={colSpan} className="text-center">
            <OverlayTrigger overlay={<Tooltip>{translate("common.expand", { capacity: `${capacity}` })}</Tooltip>}>
              <Button
                className="p-0"
                variant="transparent"
                aria-label={translate("common.expand", { capacity: `${capacity}` })}
                onClick={expand}
              >
                <FontAwesomeIcon icon={faAngleDown} />
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
        <tr>
          <td colSpan={colSpan} className="text-center">
            <OverlayTrigger overlay={<Tooltip>{translate("common.expandAll")}</Tooltip>}>
              <Button
                className="p-0"
                variant="transparent"
                aria-label={translate("common.expandAll")}
                onClick={expandAll}
              >
                <FontAwesomeIcon icon={faAnglesDown} />
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
      </>
    );
  }

  return null;
};
