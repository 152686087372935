import * as Yup from "yup";
import { TranslateFunction } from "../../../contexts/LocalizationContext";

export const productValidationSchema = (translate: TranslateFunction) =>
  Yup.object().shape({
    id: Yup.string(),
    productName: Yup.string().required(translate("validation.fieldRequired")),
    minimumStudioVersion: Yup.string()
      .notRequired()
      .transform((value, originalValue) => (originalValue && originalValue.length > 0 ? value : undefined))
      .matches(/^(\d+\.)(\d+\.)(\d+)$/, translate("validation.invalidVersionNumber")),
    parentProductID: Yup.number()
      .required(translate("validation.fieldRequired"))
      .min(1, translate("validation.fieldRequired"))
  });
