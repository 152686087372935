import { NotificationStatus } from "./NotificationStatus";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBan, faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export const notificationStatusClassMap = new Map<NotificationStatus, string>([
  [NotificationStatus.Acknowledged, "text-warning"],
  [NotificationStatus.Inactive, "text-danger"],
  [NotificationStatus.Active, "text-success"],
  [NotificationStatus.Complete, "text-success"],
  [NotificationStatus.None, "text-success"],
  [NotificationStatus.All, "text-success"]
]);

export const notificationStatusIconMap = new Map<NotificationStatus, IconProp>([
  [NotificationStatus.Acknowledged, faExclamationTriangle],
  [NotificationStatus.Inactive, faBan],
  [NotificationStatus.Active, faCheckCircle],
  [NotificationStatus.Complete, faCheckCircle],
  [NotificationStatus.None, faCheckCircle],
  [NotificationStatus.All, faCheckCircle]
]);
