import { Formik, Form as FormikForm, useFormikContext } from "formik";
import { Button, Container } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Loader } from "../common/progress/Loader";
import { AccountSettings } from "../identity/models/AccountSettings";
import { useSettings } from "./hooks/useSettings";
import { SettingsContainer } from "./SettingsContainer";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useEffect } from "react";

export const Settings = () => {
  const { isLoading, settings, getAccountSettings } = useSettings(true);

  useEffect(() => {
    getAccountSettings();
  }, []);

  if (isLoading) return <Loader />;

  const initialValues: AccountSettings = {
    id: settings?.id ?? "",
    publishToRWSAppStore: settings?.publishToRWSAppStore ?? false,
    enablePrivateAppStore: settings?.enablePrivateAppStore ?? false,
    publishToPrivateAppStore: settings?.publishToPrivateAppStore ?? false,
    includeChangelogOnShare: settings?.includeChangelogOnShare ?? false,
    privateAppStoreGuid: settings?.privateAppStoreGuid ?? "",
    accountPreferences: {
      iconUrl: settings?.accountPreferences?.iconUrl ?? "",
      fontFamily: settings?.accountPreferences?.fontFamily ?? "",
      fontSize: settings?.accountPreferences?.fontSize ?? 16,
      backgroundColor: settings?.accountPreferences?.backgroundColor ?? "",
      foregroundColor: settings?.accountPreferences?.foregroundColor ?? ""
    }
  };

  return (
    <Formik<AccountSettings> initialValues={initialValues} onSubmit={() => undefined}>
      <Container className="p-0">
        <AccountSettingsForm />
      </Container>
    </Formik>
  );
};

const AccountSettingsForm = () => {
  const { values } = useFormikContext<AccountSettings>();
  const { buttonStyle } = useTheme();
  const { isLoading, handleSubmit, resetSettings } = useSettings();
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  return (
    <FormikForm>
      <SettingsContainer />
      <div className="mb-3">
        <Button type="button" variant="secondary" className="me-2" onClick={resetSettings}>
          {translate("common.reset")}
        </Button>
        <Button type="button" style={buttonStyle} onClick={() => handleSubmit(values)}>
          {translate("common.save")}
        </Button>
      </div>
    </FormikForm>
  );
};
