import { Switch } from "../common/inputs/Switch";
import { useFormikContext } from "formik";
import { AccountSettings } from "../identity/models/AccountSettings";
import { Row } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";

export const DefaultSettings = () => {
  const { values, handleChange, setFieldValue } = useFormikContext<AccountSettings>();
  const { translate } = useLocalization();

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, opositeField: keyof AccountSettings) => {
    const { checked, name } = e.target;
    setFieldValue(name, checked);
    setFieldValue(opositeField, !checked);
  };

  return (
    <Row>
      <div className="mb-3">
        <Switch
          checked={values?.includeChangelogOnShare}
          name="includeChangelogOnShare"
          onChange={handleChange}
          label={translate("settings.includeChangelog")}
        />
      </div>
      <div className="mb-3">
        <Switch
          checked={values?.publishToRWSAppStore}
          name="publishToRWSAppStore"
          onChange={(e) => handleFieldChange(e, "publishToPrivateAppStore")}
          label={translate("plugins.publishToRWSAppStore")}
        />
      </div>
      <div className="mb-3">
        <Switch
          checked={values?.publishToPrivateAppStore}
          disabled={!values.enablePrivateAppStore}
          name="publishToPrivateAppStore"
          onChange={(e) => handleFieldChange(e, "publishToRWSAppStore")}
          label={translate("plugins.publishToPrivateAppStore")}
        />
      </div>
    </Row>
  );
};
