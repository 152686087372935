import { useFormikContext } from "formik";
import { Switch } from "../common/inputs/Switch";
import { AccountSettings } from "../identity/models/AccountSettings";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { GenerateGuidInput } from "./GenerateGuidInput";
import { useLocalization } from "../../contexts/LocalizationContext";

export const AdvancedSettings = () => {
  const { values, setFieldValue } = useFormikContext<AccountSettings>();
  const { user } = useAuth0<ExtendedUser>();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const { translate } = useLocalization();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFieldValue("enablePrivateAppStore", checked);
    setFieldValue("publishToRWSAppStore", false);

    if (!checked) setFieldValue("publishToPrivateAppStore", checked);
  };

  return (
    <div className="mb-3">
      <div className="mb-2 d-flex align-items-center">
        <Switch
          checked={values?.enablePrivateAppStore}
          disabled={!isSystemAdmin}
          name="enablePrivateAppStore"
          onChange={onChange}
          label={translate("settings.enablePrivateAppStore")}
        />
        {!isSystemAdmin && (
          <OverlayTrigger overlay={<Tooltip>{translate("settings.contactSystemAdministrator")}</Tooltip>}>
            <FontAwesomeIcon icon={faInfoCircle} className="ms-2 cursor-pointer" />
          </OverlayTrigger>
        )}
      </div>
      {values.enablePrivateAppStore && <GenerateGuidInput />}
    </div>
  );
};
