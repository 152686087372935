import { Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Resource } from "../plugins/models/Resource";

type ImagePreviewProps = { resource: Resource; onRemove: () => void };

const formatFileSize = (sizeInBytes: number) => {
  if (sizeInBytes < 1024) return `${sizeInBytes} bytes`;
  if (sizeInBytes < 1024 * 1024) return `${(sizeInBytes / 1024).toFixed(2)} KB`;
  return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
};

export const ImagePreview = ({ resource, onRemove }: ImagePreviewProps) => {
  const encodedResourceDownloadUrl = encodeURI(resource.downloadUrl);

  return (
    <div className="d-flex p-3 border rounded jsutify-content-between w-100 mb-3">
      <div className="d-flex align-items-center w-100">
        <Image thumbnail src={encodedResourceDownloadUrl} width={50} alt={resource.name} className="me-3" />
        <div className="w-100">
          <p className="card-title mb-0 text-truncate" title={resource.name}>
            {resource.name}
          </p>
          <p className="text-muted mb-0">{formatFileSize(resource.size)}</p>
        </div>
      </div>
      <div className="d-flex justify-content-end w-5">
        <Button variant="transparent p-0 d-flex">
          <FontAwesomeIcon icon={faTimes} onClick={onRemove} />
        </Button>
      </div>
    </div>
  );
};
