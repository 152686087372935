import { useLocalization } from "../../../contexts/LocalizationContext";
import { useUploadResourcesArea } from "../../plugins/hooks/useUploadResourcesArea";
import { ImagePreview } from "../ImagePreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { Form, FormGroup } from "react-bootstrap";
import { Resource } from "../../plugins/models/Resource";

type UploadResourcesAreaProps = {
  resources: Resource[];
  name: string;
  onUpload: (files: File[]) => Promise<void>;
  onDelete: (resourceId: number) => Promise<void>;
};

export const UploadResourcesArea = ({ resources, name, onDelete, onUpload }: UploadResourcesAreaProps) => {
  const { translate } = useLocalization();
  const useResourcesArea = useUploadResourcesArea({ onDelete, onUpload });
  const { dragOver, handleDrop, handleDragOver } = useResourcesArea;
  const { handleDragLeave, handleFileChange, handleRemoveResource } = useResourcesArea;

  const uploadAreaStyle: React.CSSProperties = {
    transition: "all .1s ease-in-out",
    backgroundColor: dragOver ? "#d9dadb" : "#f8f9fa",
    width: "100%",
    border: "2px dashed gray"
  };

  return (
    <div>
      <p className="font-weight-bold mb-3 fs-5">{translate("plugins.attachAndUploadFiles")}</p>
      <div
        className="mb-2 p-5 rounded"
        style={uploadAreaStyle}
        onDrop={handleDrop}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
      >
        <div className="d-flex flex-column justify-content-center align-items-center mt-3 text-center">
          <FontAwesomeIcon icon={faFileAlt} />
          <FormGroup>
            <Form.Label htmlFor={name} className="fw-bold me-1 cursor-pointer">
              {translate("file.chooseFile")}
            </Form.Label>
            <Form.Control
              id={name}
              name={name}
              type="file"
              onChange={handleFileChange}
              className="w-100"
              accept=".avif, .gif, .jpg, .jfif, .pjpeg, .pfp, .png, .zip, .rar, .7z"
              multiple
              hidden
            />
            {translate("file.dropItHere")}
          </FormGroup>
          <p className="text-muted small mb-2">
            {translate("plugins.supportedFileFormats")}:{" "}
            <strong>.avif, .gif, .jpg, .jfif, .pjpeg, .pfp, .png, .zip, .rar, .7z</strong>
          </p>
        </div>
      </div>
      {resources?.map((resource, index) => (
        <ImagePreview key={index} resource={resource} onRemove={() => handleRemoveResource(resource.id)} />
      ))}
    </div>
  );
};
