import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { useVersionActions } from "./hooks/useVersionActions";
import { RejectionReasonModal } from "./RejectionReasonModal";
import { versionValidationSchema } from "./schemas/VersionValidationSchema";
import { useTheme } from "../../contexts/ThemeContext";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useParams } from "react-router-dom";
import { PluginVersion } from "./models/PluginVersion";
import { ReviewType } from "./models/ReviewType";
import DOMPurify from "dompurify";

export const PendingVersionButtonGroup = () => {
  const formikContext = useFormikContext<PluginVersion>();
  const { values, setTouched, handleSubmit } = formikContext;
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth0<ExtendedUser>();
  const { isLoading, versionActions } = useVersionActions();
  const { buttonStyle } = useTheme();
  const params = useParams();
  const { translate } = useLocalization();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const versionId = parseInt(params.versionId as string);

  const handleSave = async () => {
    try {
      values.htmlChangelog = DOMPurify.sanitize(values.htmlChangelog);
      await versionValidationSchema(translate).validate(values, { abortEarly: false });
      setTouched({});
      if (isSystemAdmin) await versionActions.handleUpdate(versionId, values);
      else await versionActions.handleUpdatePending(versionId, values);
    } catch (errors) {
      handleSubmit();
    }
  };

  if (isLoading) return <Loader />;

  if (isSystemAdmin) {
    return (
      <>
        <Button
          style={buttonStyle}
          type="button"
          onClick={() => versionActions.handleRequestReview(versionId, ReviewType.Approved)}
        >
          {translate("plugins.approve")}
        </Button>
        <Button variant="danger" className="mx-2" type="button" onClick={() => setShowModal(true)}>
          {translate("plugins.reject")}
        </Button>
        {showModal && (
          <RejectionReasonModal
            onContinue={(rejectionReason) =>
              versionActions.handleRequestReview(versionId, ReviewType.Rejected, rejectionReason)
            }
            onClose={() => setShowModal(false)}
          />
        )}
      </>
    );
  }

  return (
    <Button style={buttonStyle} className="me-2" type="button" onClick={handleSave}>
      {values.publishToRWSAppStore ? translate("plugins.submit") : translate("common.save")}
    </Button>
  );
};
