import { useState } from "react";
import { DisabledFields } from "../models/DisabledFields";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFormikContext } from "formik";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { PluginVersion } from "../models/PluginVersion";
import { getExistingResourcesSize, validateResourcesTypeAndSize } from "../../../utils";
import { useFetch } from "../../../hooks/useFetch";
import { ResourceType } from "../models/ResourceType";
import { Resource } from "../models/Resource";
import { apiRoutes } from "../../../apiRoutes";
import { useDownload } from "../../../hooks/useDownload";
import { useParams } from "react-router-dom";

export const useVersionDetailsPartial = (hasLockedFields: boolean) => {
  const [disabledFields, setDisabledFields] = useState<DisabledFields>(new DisabledFields(hasLockedFields));
  const { values, setFieldValue } = useFormikContext<PluginVersion>();
  const { showModal } = useFeedback();
  const { translate } = useLocalization();
  const { request } = useFetch();
  const { pushNotification } = useFeedback();
  const { handleDownload } = useDownload();
  const { pluginId } = useParams() as { pluginId: string };

  const handleFieldDisable = (field: keyof DisabledFields) => {
    setDisabledFields((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, opositeField: keyof PluginVersion) => {
    const checked = e.target.checked;
    const name = opositeField.charAt(0).toUpperCase() + opositeField.slice(1);
    const fieldName = e.target.name as keyof PluginVersion;

    if (checked && values[opositeField]) {
      showModal({
        isVisible: true,
        message: translate("versions.disableNameOption", { name: name }),
        handleClose: () => showModal(null),
        handleContinue: () => {
          showModal(null);
          setFieldValue(fieldName, checked);
          setFieldValue(opositeField, false);
        }
      });
    } else {
      setFieldValue(fieldName, checked);
    }
  };

  const savePluginVersionResource = async (files: File[]) => {
    const existingResourcesSize = getExistingResourcesSize(values.changelogResources);
    const { isValid, message } = validateResourcesTypeAndSize(files, existingResourcesSize);

    if (!isValid) {
      pushNotification({ message: translate(message), type: "danger" });
      return;
    }

    await handleSavePluginVersionResource(files);
  };

  const handleChangelogDownload = async (pluginId: number, versionId: number) => {
    await handleDownload(apiRoutes.pluginVersion.downloadVersionChangelog(pluginId, versionId), undefined, "POST");
  };

  const deletePluginVersionResource = async (resourceId: number) => {
    await handleDeletePluginResource(resourceId);
  };

  const handleSavePluginVersionResource = async (files: File[]) => {
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));
    formData.set("type", `${ResourceType.Changelog}`);

    const onSuccess = async (response: Response) => {
      const json = await response.json();
      if (Array.isArray(json)) {
        updateResourceInFormikContext(json, "add");
      }

      pushNotification({
        message: translate("plugins.imagesUploadedSucessfully"),
        type: "success"
      });
    };

    await request({
      route: apiRoutes.resources.addPluginVersionResources(parseInt(pluginId), values.versionId),
      method: "POST",
      body: formData,
      onSuccess,
      onFailure: async (response: Response) => {
        const json = await response.json();
        pushNotification({ ...json, type: "danger" });
      }
    });
  };

  const handleDeletePluginResource = async (resourceId: number) => {
    await request({
      route: apiRoutes.resources.deletePluginVersionResource(parseInt(pluginId), values.versionId, resourceId),
      method: "DELETE",
      onSuccess: async () => {
        updateResourceInFormikContext([{ id: resourceId }] as Resource[], "remove");
        pushNotification({
          message: translate("plugins.imageDeletedSucessfully"),
          type: "success"
        });
      },
      onFailure: () =>
        pushNotification({
          message: translate("plugins.failedToDeleteImage"),
          type: "danger"
        })
    });
  };

  const updateResourceInFormikContext = (resources: Resource[], action: "add" | "remove") => {
    const getUpdatedResources = (existingResources: Resource[]) => {
      return action === "add"
        ? [...existingResources, ...resources]
        : existingResources.filter((x) => !resources.some((resource) => resource.id === x.id));
    };
    setFieldValue("changelogResources", getUpdatedResources(values.changelogResources));
    setFieldValue("resourcesModified", true);
  };

  return {
    disabledFields,
    actions: {
      handleChangelogDownload,
      handleFieldDisable,
      handleSwitchChange,
      savePluginVersionResource,
      deletePluginVersionResource
    }
  };
};
