import { useFormikContext } from "formik";
import { draftPluginValidationSchema } from "../schemas/DraftPluginValidationSchema";
import { PluginDetails } from "../models/PluginDetails";
import { usePluginActions } from "./usePluginActions";
import { useParams } from "react-router-dom";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { pluginValidationSchema } from "../schemas/PluginValidationSchema";
import DOMPurify from "dompurify";

export const useDraftPluginButtonGroup = () => {
  const { values, setTouched, handleSubmit: submit } = useFormikContext<PluginDetails>();
  const { pluginId } = useParams();
  const { translate } = useLocalization();
  const { isLoading, pluginActions } = usePluginActions();

  const handleSaveAsDraft = async () => {
    try {
      purifyValues();
      await draftPluginValidationSchema(translate).validate(values, { abortEarly: false });
      setTouched({});
      if (pluginId) await pluginActions.handleUpdateDraft(parseInt(pluginId), values);
      else await pluginActions.handleCreateDraft(values);
    } catch (errors) {
      setTouched({ name: true, parentProductDetailsId: true });
    }
  };

  const handleSave = async () => {
    try {
      purifyValues();
      await pluginValidationSchema(translate).validate(values, { abortEarly: false });
      setTouched({});
      if (pluginId) await pluginActions.handleUpdate(parseInt(pluginId), values);
      else await pluginActions.handleCreate(values);
    } catch (errors) {
      submit();
    }
  };

  const handleSubmit = async () => {
    try {
      purifyValues();
      await pluginValidationSchema(translate).validate(values, { abortEarly: false });
      setTouched({});
      if (pluginId) await pluginActions.handleUpdatePending(parseInt(pluginId), values);
      else await pluginActions.handleCreatePending(values);
    } catch (errors) {
      submit();
    }
  };

  const purifyValues = () => {
    values.htmlDescription = DOMPurify.sanitize(values.htmlDescription);
    values.htmlDocumentation = DOMPurify.sanitize(values.htmlDocumentation);
  };

  return { isLoading, handleSaveAsDraft, handleSave, handleSubmit };
};
