import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import { Button } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { useVersion } from "./contexts/VersionContext";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { useVersionActions } from "./hooks/useVersionActions";
import { Status } from "./models/Status";
import { versionValidationSchema } from "./schemas/VersionValidationSchema";
import { useState } from "react";
import { InformationMissingModal } from "./InformationMissingModal";
import { DeleteAction } from "./models/DeleteAction";
import { usePlugin } from "./contexts/PluginContext";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useParams } from "react-router-dom";
import { PluginVersion } from "./models/PluginVersion";
import DOMPurify from "dompurify";

export const PublishedVersionButtonGroup = () => {
  const { values, setTouched, handleSubmit } = useFormikContext<PluginVersion>();
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth0<ExtendedUser>();
  const { buttonStyle } = useTheme();
  const { version } = useVersion();
  const { pluginDetails: plugin } = usePlugin();
  const params = useParams();
  const { isLoading, versionActions } = useVersionActions();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const { publishToRWSAppStore, publishToPrivateAppStore } = values;
  const isPublish = publishToRWSAppStore || publishToPrivateAppStore;
  const { translate } = useLocalization();
  const versionId = parseInt(params.versionId as string);

  const handleSave = async () => {
    try {
      values.htmlChangelog = DOMPurify.sanitize(values.htmlChangelog);
      await versionValidationSchema(translate).validate(values, { abortEarly: false });
      if (isPublish && plugin.hasMissingInformation) setShowModal(true);
      else {
        setTouched({});
        if (isSystemAdmin) await versionActions.handleUpdate(versionId, values);
        else await versionActions.handleUpdatePending(versionId, values);
      }
    } catch (errors) {
      handleSubmit();
    }
  };

  const handleModalClose = () => setShowModal(false);

  if (isLoading) return <Loader />;

  return (
    <>
      {version.deleteInstructions === DeleteAction.None && (
        <Button style={buttonStyle} className="me-2" type="button" onClick={handleSave}>
          {values.publishToRWSAppStore && !isSystemAdmin ? translate("plugins.submit") : translate("common.save")}
        </Button>
      )}

      {version.status === Status.Published && (
        <Button
          variant="warning"
          className="me-2"
          onClick={() => versionActions.changeVersionStatus(Status.NotPublished, versionId)}
        >
          {translate("plugins.deactivate")}
        </Button>
      )}

      {version.status === Status.NotPublished && (
        <Button
          variant="warning"
          className="me-2"
          onClick={() => versionActions.changeVersionStatus(Status.Published, versionId)}
        >
          {translate("plugins.activate")}
        </Button>
      )}
      {showModal && <InformationMissingModal onClose={handleModalClose} />}
    </>
  );
};
