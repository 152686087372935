import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { usePluginActions } from "./hooks/usePluginActions";
import { DeleteAction } from "./models/DeleteAction";
import { DeleteButton } from "./DeleteButton";
import { faExclamationCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useLocalization } from "../../contexts/LocalizationContext";
import { ReviewType } from "./models/ReviewType";

type DeletePluginButtonGroupProps = {
  id: number;
  hasIcon?: boolean;
  showOverlay?: boolean;
  deleteInstructions: DeleteAction;
  futureInstructions: DeleteAction;
};

export const DeletePluginButtonGroup = ({
  hasIcon,
  showOverlay,
  id: pluginId,
  deleteInstructions,
  futureInstructions
}: DeletePluginButtonGroupProps) => {
  const { isLoading, pluginActions } = usePluginActions();
  const { user } = useAuth0<ExtendedUser>();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const { translate } = useLocalization();

  const onDelete = async () => {
    if (isSystemAdmin) await pluginActions.handleDelete(pluginId, futureInstructions);
    else await pluginActions.requestDeletion(pluginId, futureInstructions);
  };

  if (isLoading) return <Loader />;

  if (deleteInstructions === DeleteAction.None) {
    return <DeleteButton hasIcon={hasIcon} showOverlay={showOverlay} onClick={onDelete} />;
  }

  if (isSystemAdmin) {
    return (
      <div className="d-flex align-items-center">
        <OverlayTrigger
          trigger="click"
          rootClose
          overlay={
            <Popover id="popover-basic" style={{ zIndex: 1000 }}>
              <Popover.Header as="h3">{translate("plugins.deletionRequest")}</Popover.Header>
              <Popover.Body>
                <Button
                  variant="success"
                  type="button"
                  onClick={() => pluginActions.reviewDeletionRequest(pluginId, ReviewType.Approved)}
                >
                  {translate("plugins.approve")}
                </Button>
                <Button
                  variant="danger ms-2"
                  onClick={() => pluginActions.reviewDeletionRequest(pluginId, ReviewType.Rejected)}
                >
                  {translate("plugins.reject")}
                </Button>
              </Popover.Body>
            </Popover>
          }
        >
          <DeleteButton hasIcon={hasIcon} />
        </OverlayTrigger>
        <OverlayTrigger placement="right" overlay={<Tooltip>{translate("plugins.deletionApprovalNeeded")}</Tooltip>}>
          <FontAwesomeIcon icon={faExclamationCircle} className="text-warning ms-2 cursor-pointer" />
        </OverlayTrigger>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center">
      <DeleteButton disabled hasIcon={hasIcon} />
      <OverlayTrigger placement="right" overlay={<Tooltip>{translate("plugins.deletionRequestPending")}</Tooltip>}>
        <FontAwesomeIcon icon={faInfoCircle} className="text-warning ms-2 cursor-pointer" />
      </OverlayTrigger>
    </div>
  );
};
