import { Table } from "react-bootstrap";
import { ExtendedAccount } from "../identity/models/ExtendedAccount";
import { AccountsFilter } from "./model/AccountsFilter";
import { TableHead } from "./TableHead";
import { SearchableTableHead } from "./SearchableTableHead";
import { UserAccount } from "../identity/models/UserAccount";
import { AccountsTableRow } from "./AccountsTableRow";
import { useState } from "react";
import { SortableTableHead } from "./SortableTableHead";
import { useLocalization } from "../../contexts/LocalizationContext";
import { AccountsFilterActionType } from "./model/AccountsFilterActionType";
import { TableFooter } from "../common/TableFooter";

type AccountsTableProps = {
  accounts: ExtendedAccount[];
  filters: AccountsFilter;
  handleFiltersChange: (action: AccountsFilterActionType, value?: string) => void;
  onAccountSelect: (value: UserAccount) => void;
  onPin: (isFavourite: boolean, accountId: string) => void;
};

const defaultCapacity = 10;

export const AccountsTable = ({
  filters,
  accounts,
  onPin,
  handleFiltersChange,
  onAccountSelect
}: AccountsTableProps) => {
  const [capacity, setCapacity] = useState(defaultCapacity);
  const newAccounts = accounts.slice(0, capacity);
  const { translate } = useLocalization();

  return (
    <Table striped bordered>
      <thead>
        <tr className="d-none d-xl-table-row text-center align-middle">
          <th>
            <TableHead
              value={filters.AccountName}
              name={translate("account.accountName")}
              sortType={filters.AccountNameSort}
              setSearch={(value) => handleFiltersChange(AccountsFilterActionType.SetAccountName, value)}
              setSortType={(type) => handleFiltersChange(AccountsFilterActionType.SetAccountNameSort, type)}
            />
          </th>
          <th>
            <TableHead
              value={filters.AccountAlias}
              setSearch={(value) => handleFiltersChange(AccountsFilterActionType.SetAccountAlias, value)}
              setSortType={(type) => handleFiltersChange(AccountsFilterActionType.SetAccountAliasSort, type)}
              name={translate("account.accountAlias")}
              sortType={filters.AccountAliasSort}
            />
          </th>
          <th style={{ minWidth: 125 }}>
            <SearchableTableHead
              value={filters.AppName}
              name={translate("account.apps")}
              setSearch={(value) => handleFiltersChange(AccountsFilterActionType.SetAppName, value)}
            />
          </th>
          <th style={{ minWidth: 125 }}>
            <SearchableTableHead
              value={filters.User}
              name={translate("account.users")}
              setSearch={(value) => handleFiltersChange(AccountsFilterActionType.SetUser, value)}
            />
          </th>
          <th>
            <div className="d-flex align-items-center">
              <p className="m-0 text-nowrap">{translate("plugins.createdDate")}</p>
              <SortableTableHead
                sortType={filters.CreatedDateSort}
                setSortType={(type) => handleFiltersChange(AccountsFilterActionType.SetCreatedDateSort, type)}
              />
            </div>
          </th>
          <th>
            <div className="d-flex align-items-center">
              <p className="m-0 text-nowrap">{translate("plugins.recentAction")}</p>
              <SortableTableHead
                sortType={filters.RecentActionSort}
                setSortType={(type) => handleFiltersChange(AccountsFilterActionType.SetRecentActionSort, type)}
              />
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {newAccounts.map((x) => (
          <AccountsTableRow key={x.id} account={x} onAccountSelect={onAccountSelect} onPin={onPin} />
        ))}
        <TableFooter
          expand={() => setCapacity(defaultCapacity)}
          expandAll={() => setCapacity(accounts.length)}
          capacity={defaultCapacity}
          showMenu={accounts.length > defaultCapacity}
          showEmpty={!newAccounts.length}
          colSpan={7}
        />
      </tbody>
    </Table>
  );
};
