import { TranslateFunction } from "../../../contexts/LocalizationContext";
import * as Yup from "yup";

const requiredDocumentationHeaders = [
  "# App Name",
  "## Overview",
  "## Key Features",
  "## Installation Guide",
  "### Requirements",
  "### Step-by-Step Installation",
  "## Configuration and Setup",
  "### Configuration Steps",
  "## Usage Instructions",
  "### Basic Usage",
  "### Advanced Tips",
  "## Troubleshooting and FAQs",
  "## License",
  "## Acknowledgements"
];

export const markdownContentValidator = (translate: TranslateFunction, isDocumentation = false) => {
  let validator = Yup.string()
    .required(translate("validation.fieldRequired"))
    .min(20, translate("validation.minimumCharacters"))
    .test("has-below-max-size", translate("validation.markdownFileSizeExceeded"), function (value) {
      return new TextEncoder().encode(value).length <= 200 * 1024 * 1024;
    });

  if (isDocumentation) {
    validator = validator.test(
      "has-required-headers",
      translate("validation.markdownMissingHeaders"),
      function (value) {
        return requiredDocumentationHeaders.every((header) => value.includes(header));
      }
    );
  }

  return validator;
};

export const resourceTypeAndSizeValidator = (isRequired: boolean, translate: TranslateFunction) => {
  const schema = Yup.mixed()
    .test("fileType", translate("validation.invalidFileFormat"), resourceTypeValidation)
    .test("fileSize", translate("validation.imageSizeExceeded"), resourceSizeValidator);

  return isRequired ? schema.required(translate("validation.fieldRequired")) : schema.notRequired();
};

const resourceTypeValidation = (value: unknown) => {
  if (!(value instanceof Blob)) return true;
  const supportedFormats = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp", "image/webp"];
  return supportedFormats.includes(value.type);
};

const resourceSizeValidator = (value: unknown) => {
  if (!(value instanceof Blob)) return true;
  return value.size <= 10 * 1024 * 1024;
};
